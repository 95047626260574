
import { Component, Vue } from 'vue-property-decorator';
import OnboardLayout from '@/components/OnboardLayout.vue';

// Utilities
import { AuthService } from '@/services';

const LOGOUT_DELAY = 1500;

@Component({
  components: { OnboardLayout }
})
export default class Logout extends Vue {
  mounted(): void {
    // Signout after a delay (so user sees something happening)
    setTimeout(async () => {
      await AuthService.logout();

      window.location.replace('/login');
    }, LOGOUT_DELAY);
  }
}
